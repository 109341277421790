import React, {useEffect} from 'react'
import Helmet from 'react-helmet'
import '@fontsource/open-sans'
import '@fontsource/donegal-one'

import Header from './header'
import styles from './layout.module.css'
import '../styles/layout.css'
import Marquee3k from 'marquee3000';
import { Link } from 'gatsby'


const Layout = ({ children, onHideNav, onShowNav, showNav, siteTitle, backgroundColor }) => {

  useEffect(() => {
    Marquee3k.init();
}, [])

return (

  <>
    <Helmet>
    </Helmet>
    <Header
      siteTitle={siteTitle}
    ></Header>
<div className={styles.background}>
    <div className={styles.marqueeContainer}>
      {/* <div className={styles.dots}>• • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • </div>
      */} 
      <div className="marquee3k" data-speed="0.25" data-reverse="" data-pausable="bool" >
        <div>
          <nav className={styles.marqueeInternal}>
          <span><Link to="/" className={styles.marqueeInternalB}>Jarren.Rocks</Link></span>
          <span><Link to="/work" className={styles.marqueeInternalS}>Work</Link></span>
          <span><Link to="/about" className={styles.marqueeInternalS}>About</Link></span>
          <span><Link to="/contact" className={styles.marqueeInternalS}>Contact</Link></span>
          <span><Link to="/climate" className={styles.marqueeInternalS}>Climate</Link></span>
          </nav>
        </div>
      </div>
      {/* <div className={styles.dots}>• • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • </div>
    */} </div>

       {children}
    <footer className={styles.footer}>

    <div className={styles.marqueeContainer}>
      <div className="marquee3k" data-speed="0.25" data-reverse="" data-pausable="bool" >
        <div>
          <div className={styles.marqueeInternal}>
          <span>
            <p>
              <a
                href="https://www.youtube.com/channel/UCy2DlxCMf_1oE7SWuGWtJ0A"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.marqueeInternalS}
              >
                YouTube
              </a>
            </p>
          </span>
          <span>
            <p>
              <a
                href="https://www.tiktok.com/@jarrenrocks"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.marqueeInternalS}
                >
                TikTok
              </a>
            </p>
          </span>
          <span>
            <p> 
              <a
                href="https://www.twitter.com/jarrenrocks_"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.marqueeInternalS}
                >
                Twitter
              </a>
            </p>
          </span>
          <span>
            <p>
              <div className={styles.marqueeInternalS} >
                &copy; {new Date().getFullYear()} • Jarren Rocks
              </div>
            </p>
          </span>
          </div>
        </div>
      </div>
      </div>

    </footer>
    </div>
  </>
)
}




export default Layout
